import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import NavMenu from './NavMenu'


const NavBar = () => {

    useEffect(() => {
        if(window && window.init_template){
            window.init_template();
        } 
    },[]);

  return (
    <div className="pt-1 mb-4 fixed-top shadow-sm bg-white">
    <div className="page-title d-flex">
        <div className="align-self-center me-auto">
            <div className="icon bg-white text-dark rounded-circle ">
                <img style={{marginTop: "10px"}} src='https://res.cloudinary.com/dg8pxsdme/image/upload/w_100,h_41,c_fit/globalfunds-logo_kci1xt.png'/>
            </div>
        </div>
        <div className="align-self-center ms-auto">
            <Link to="#"
            data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-login">
                <i translate="no" className="material-icons-sharp text-dark" style={{fontSize: "28px", marginTop: "12px"}}>apps</i>
            </Link>
        </div>
    </div>
    <NavMenu/>
</div>

  )
}

export default NavBar
