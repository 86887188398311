import React from 'react'
import { Link } from 'react-router-dom'
const NavMenu = () => {

    const hideNavigation = () => {
        var togglerFooter = document.querySelector('#footer-toggle');
        if(togglerFooter){
            togglerFooter.click();
        }
    }

  return (
    <div id="menu-login" className="offcanvas offcanvas-start offcanvas-attached">
    {/* <!-- menu-size will be the dimension of your menu. If you set it to smaller than your content it will scroll--> */}
    <div className="menu-size" style={{width: "230px"}}>
        {/* <!-- Menu Title--> */}
        <div className="pt-3">
            <div className="divider divider-margins mb-3 opacity-50"></div>

            {/* <!-- Main Menu List--> */}
            <div className="list-group list-custom list-menu-large">
                <a href="https://www.global-funds.org/" id="lome-link" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-house-fill"></i>
                    <div>Home</div>
                    
                </a>
                <a href="https://www.global-funds.org/about" id="loan-link" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-bar-chart-line"></i>
                    <div>About Us</div>
                    
                </a>   

                

                <a href="https://www.global-funds.org/started" id="lending-link" data-submenu="sub1" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-arrow-down"></i>
                    <div>Get Started</div>
                    
                </a>

                <a href="https://www.global-funds.org/plans" id="loan-link" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs  bi-server"></i>
                    <div>Investment Plan</div>
                    
                </a>

                <a href="https://www.global-funds.org/faq" id="wallet-link" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                    <div>FAQ</div>
                </a>


                <a href="/https://www.global-funds.org/rating" id="lending-link" data-submenu="sub1" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-download"></i>
                    <div>Rate Us</div>
                    
                </a>

                <a href="https://www.global-funds.org/contact" id="wallet-link" className="list-group-item">
                    <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                    <div>Contact Us</div>
                </a>
                
            </div>


            <div className="divider divider-margins mb-3 opacity-50"></div>

{/* <!-- Quick Actions Icons--> */}
<div className="content pt-1">
    <div className="d-flex text-center">
        <div className="me-auto">
            <Link onClick={hideNavigation} to="/login"  className="icon icon-s rounded-s gradient-red shadow-bg shadow-bg-xs"><i className="font-16 color-white bi bi-arrow-up"></i></Link>
            <h6 className="font-11 font-400 opacity-70 mb-n1 pt-2">Login</h6>
        </div>
        <div className="m-auto">
            <Link onClick={hideNavigation} to="/register"  className="icon icon-s rounded-s gradient-green shadow-bg shadow-bg-xs"><i className="font-16 color-white bi bi-arrow-down"></i></Link>
            <h6 className="font-11 font-400 opacity-70 mb-n1 pt-2">Register</h6>
        </div>
    </div>
</div>


            <div className="divider divider-margins opacity-50"></div>
            {/* <!-- Menu Copyright --> */}
            <p className="px-3 font-9 opacity-30 color-theme mt-n3">&copy; GLOBAL FUNDS<i className="bi bi-heart-fill color-red-dark px-1"></i>www.global-funds.org</p>
        </div>
    </div>
</div>
  )
}

export default NavMenu
